import { useEffect } from 'react';
import { useIdentify, AuthState } from '@hungryroot/customer';
import { useCustomerExperiments } from '@hungryroot/experiments';
import { segmentAnalytics as analytics } from '@hungryroot/segment';

type Props = {
  analytics?: Pick<typeof analytics, 'identify' | 'page'>;
};

/**
 *
 * This component is designed to log data to segment once,
 * as close to HTML load time as possible. This may be generalized in
 * the future to support more SPA cases (eg- SPA navigations) but that
 * is not the case right now.
 *
 */
export function Segment({ analytics: segment = analytics }: Props) {
  const user = useIdentify();
  const { data: experimentsData } = useCustomerExperiments();

  useEffect(() => {
    if (user.authState !== AuthState.isAuthenticated) {
      return;
    }
    const { customerData } = user;
    const { id, email } = customerData;
    segment.identify(`${id}`, {
      email,
    });
  }, [segment, user]);

  useEffect(() => {
    if (experimentsData === undefined) {
      return;
    }

    segment.page(undefined, undefined, {
      experiments: experimentsData.experiments,
    });
  }, [segment, experimentsData]);

  return null;
}
