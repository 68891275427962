import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { string as zString } from 'zod';
import { useExperimentDefinitions } from '@hungryroot/experiments';

const GOOGLE_ANALYTICS_ID = zString().parse(
  process.env.PUBLIC_GOOGLE_ANALYTICS_ID
);
const GOOGLE_OPTIMIZE_KEY = zString().parse(
  process.env.PUBLIC_GOOGLE_OPTIMIZE_KEY
);

export function GoogleAnalytics() {
  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
      testMode: process.env.NODE_ENV === 'test',
    });

    ReactGA.ga('require', GOOGLE_OPTIMIZE_KEY);
    ReactGA.ga('send', 'pageview');
  }, []);

  const experiments = useExperimentDefinitions({
    limit: 500,
  });
  const { data, isFetched: expOptimizeIdFetched } = experiments;
  const allExperiments = data?.results;
  const experimentOptimizeId = allExperiments?.find(
    (exp) => exp.optimize_id !== null
  )?.optimize_id;

  useEffect(() => {
    if (expOptimizeIdFetched && experimentOptimizeId) {
      ReactGA.set({ exp: experimentOptimizeId });
    }
  }, [expOptimizeIdFetched, experimentOptimizeId]);

  return null;
}
